import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { FiltersInitialValues } from '@modules/playerOffers/submodules/TableFilters/constants';
import {
  type IFilterItem,
  type IFiltersInitialValues,
} from '@modules/playerOffers/types';
import {
  OPERATOR_SORT,
  type IFilterChip,
  type ISearchParams,
} from '@features/filters/types';

interface InitialState {
  chipList: IFilterChip[];
  gridQueryParams: string;
  filterList: IFilterItem[];
  searchParams: ISearchParams;
  prevFilters: IFiltersInitialValues;
  activePlayerOfferId: null | string;
}

export const initialPlayerOffersSearchParams = {
  page: 1,
  size: 10,
  filters: [],
  sorts: [
    {
      field: 'given_at',
      order: OPERATOR_SORT.DESC,
    },
  ],
};

const initialState: InitialState = {
  chipList: [],
  filterList: [],
  gridQueryParams: '',
  activePlayerOfferId: null,
  prevFilters: FiltersInitialValues,
  searchParams: initialPlayerOffersSearchParams,
};

const playerOffersSlice = createSlice({
  initialState,
  name: 'playerOffersSlice',
  reducers: {
    resetChipList(state: InitialState) {
      state.chipList = initialState.chipList;
    },
    setChipList(state: InitialState, action: PayloadAction<IFilterChip[]>) {
      state.chipList = action.payload;
    },
    setFilterList(state: InitialState, action: PayloadAction<IFilterItem[]>) {
      state.filterList = action.payload;
    },
    setSearchParams(state: InitialState, action: PayloadAction<ISearchParams>) {
      state.searchParams = action.payload;
    },
    addChipItem(state: InitialState, action: PayloadAction<IFilterChip>) {
      state.chipList = [...state.chipList, action.payload];
    },
    addFilter(state: InitialState, action: PayloadAction<IFilterItem>) {
      state.filterList = [...state.filterList, action.payload];
    },
    setPrevFilters(
      state: InitialState,
      action: PayloadAction<InitialState['prevFilters']>
    ) {
      state.prevFilters = action.payload;
    },
    setGridQueryParams(
      state: InitialState,
      action: PayloadAction<InitialState['gridQueryParams']>
    ) {
      state.gridQueryParams = action.payload;
    },
    removeChipItem(state: InitialState, action: PayloadAction<string>) {
      state.chipList = state.chipList.filter(
        (item) => item.field !== action.payload
      );
    },
    setActivePlayerOfferId(
      state: InitialState,
      action: PayloadAction<InitialState['activePlayerOfferId']>
    ) {
      state.activePlayerOfferId = action.payload;
    },
    removeFilter(state: InitialState, action: PayloadAction<string>) {
      state.filterList = state.filterList.filter(
        (item) => item.value !== action.payload
      );
    },
  },
});

export default playerOffersSlice.reducer;

export const {
  addFilter,
  addChipItem,
  setChipList,
  removeFilter,
  resetChipList,
  setFilterList,
  removeChipItem,
  setPrevFilters,
  setSearchParams,
  setGridQueryParams,
  setActivePlayerOfferId,
} = playerOffersSlice.actions;
