import { FORM_ELEMENT, OPERATOR_TYPE } from '@features/filters/types';
import { type IFiltersInitialValues } from '@modules/playerOffers/types';

export enum FILTERS_ENTITY {
  ACCEPTED_AT = 'acceptedAt-At',
  ACCEPTED_TO = 'acceptedAt-To',
  BRAND_ID = 'brandId',
  CAMPAIGN_ID = 'campaignId',
  COUNTRY_ID = 'countryId',
  DECLINED_AT = 'declinedAt-At',
  DECLINED_TO = 'declinedAt-To',
  EXPIRES_AT = 'expiresAt-At',
  EXPIRES_TO = 'expiresAt-To',
  FILTER_TYPE = 'filterType',
  GIVEN_AT = 'givenAt-At',
  GIVEN_TO = 'givenAt-To',
  ID = 'id',
  PLAYER_ID = 'playerId',
  STATUS = 'status',
}

export const FilterList = [
  {
    label: 'Player ID',
    value: FILTERS_ENTITY.PLAYER_ID,
    formElement: FORM_ELEMENT.INPUT,
  },
  {
    label: 'Offer ID',
    value: FILTERS_ENTITY.ID,
    formElement: FORM_ELEMENT.INPUT,
  },
  {
    label: 'Brand',
    value: FILTERS_ENTITY.BRAND_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
  },
  {
    label: 'Country',
    value: FILTERS_ENTITY.COUNTRY_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
  },
  {
    label: 'Status',
    value: FILTERS_ENTITY.STATUS,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
  },
  {
    label: 'Given At From',
    value: FILTERS_ENTITY.GIVEN_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
  },
  {
    label: 'Given At To',
    value: FILTERS_ENTITY.GIVEN_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
  },
  {
    label: 'Accepted At From',
    value: FILTERS_ENTITY.ACCEPTED_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
  },
  {
    label: 'Accepted At To',
    value: FILTERS_ENTITY.ACCEPTED_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
  },
  {
    label: 'Expires At From',
    value: FILTERS_ENTITY.EXPIRES_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
  },
  {
    label: 'Expires At To',
    value: FILTERS_ENTITY.EXPIRES_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
  },
  {
    label: 'Declined At From',
    value: FILTERS_ENTITY.DECLINED_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
  },
  {
    label: 'Declined At To',
    value: FILTERS_ENTITY.DECLINED_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
  },
  {
    label: 'Campaign Name',
    value: FILTERS_ENTITY.CAMPAIGN_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
  },
];

export const FiltersInitialValues: IFiltersInitialValues = {
  [FILTERS_ENTITY.FILTER_TYPE]: null,
  [FILTERS_ENTITY.ID]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [FILTERS_ENTITY.STATUS]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.BRAND_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.COUNTRY_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.CAMPAIGN_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.PLAYER_ID]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [FILTERS_ENTITY.GIVEN_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.EXPIRES_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.GIVEN_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.DECLINED_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.ACCEPTED_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.EXPIRES_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.DECLINED_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.ACCEPTED_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
};

export const InputTypeFilters = [FILTERS_ENTITY.PLAYER_ID, FILTERS_ENTITY.ID];

export const StartDateTypeFilters = [
  FILTERS_ENTITY.GIVEN_AT,
  FILTERS_ENTITY.EXPIRES_AT,
  FILTERS_ENTITY.DECLINED_AT,
  FILTERS_ENTITY.ACCEPTED_AT,
];

export const EndDateTypeFilters = [
  FILTERS_ENTITY.GIVEN_TO,
  FILTERS_ENTITY.EXPIRES_TO,
  FILTERS_ENTITY.DECLINED_TO,
  FILTERS_ENTITY.ACCEPTED_TO,
];

export enum PLAYER_OFFERS_QUERY_PARAM {
  PLAYER_ID = 'player_id',
}
